import React from 'react';
import RightImg1 from "./../../images/00-UC-Globe.png";
import RightImg2 from "./../../images/Yealink_SIP-T46G.png";
import LeftImg1 from "./../../images/UnifiedExperience840.svg";
import LeftImg2 from "./../../images/Microsoft_Office_Teams_lg.svg";

function Whenphoning() {
    return (
        <div>
            <div className="bg-grey-normal">
        <div className="grid md:grid-cols-2 grid-cols-1 py-20 place-items-center">
            <div className="md:block hidden">
                    <img src={LeftImg2} alt="" />
                </div>
                <div className="lg:pl-20 pl-8">
                    <p className="font-normal text-xs mb-4">DEVELOPER RESOURCES</p>
                    <h1 className="text-3xl font-medium mb-4">By developers, for developers</h1>
                    <p className="text-lg font-normal mb-16">Community is a wonderful thing. Connect with ours to discover best practices, untapped solutions, helpful docs, guides, events,
                     blogs, and more. Learn, build, and share with like-minded thinkers. 
                            </p>
                    <a href="/" className="underline uppercase font-medium tracking-widest text-sm">VISIT OUR DEVELOPER CENTER</a>
                </div>   
                <div className="md:hidden block" >
                    <img src={LeftImg1} alt="" />
                </div>            
            </div>
            <div className="grid md:grid-cols-2 grid-cols-1 py-20 place-items-center">
                <div className="lg:pl-20 pl-8">
                    <p className="font-normal text-xs mb-4">VONAGE APIS</p>
                    <h1 className="text-3xl font-medium mb-4">Keep customers connected (and protected) with
                            programmable messaging, voice, video and more</h1>
                    <p className="text-lg font-normal mb-16">Gather your group with our <a href="/" className="underline">Video API</a>—great for live interactive
                            video calls, virtual tech support, telehealth appointments, remote
                            learning, and more. Mitigate fraud with our <a href="/" className="underline">Verify API</a> to build
                            convenient two-factor authentication. Or get social with real-time
                            messaging on popular social apps, like Facebook Messenger,
                            WhatsApp, and Viber. Vonage does that (and a lot more).
                            </p>
                    <a href="/" className="underline uppercase font-medium tracking-widest text-sm">SEE ALL VONAGE APIS</a>
                </div>
                <div>
                    <img src={RightImg1} alt="" />
                </div>
            </div>
            <div className="grid md:grid-cols-2 grid-cols-1 py-20 place-items-center">
            <div className="md:block hidden">
                    <img src={LeftImg1} alt="" />
                </div>
                <div className="lg:pl-20 pl-8">
                    <p className="font-normal text-xs mb-4">DEVELOPER RESOURCES</p>
                    <h1 className="text-3xl font-medium mb-4">By developers, for developers</h1>
                    <p className="text-lg font-normal mb-16">Community is a wonderful thing. Connect with ours to discover best practices, untapped solutions, helpful docs, guides, events,
                     blogs, and more. Learn, build, and share with like-minded thinkers. 
                            </p>
                    <a href="/" className="underline uppercase font-medium tracking-widest text-sm">VISIT OUR DEVELOPER CENTER</a>
                </div>   
                <div className="md:hidden block" >
                    <img src={LeftImg1} alt="" />
                </div>            
            </div>
            <div className="grid md:grid-cols-2 grid-cols-1 py-20 place-items-center">
                <div className="lg:pl-20 pl-8">
                    <p className="font-normal text-xs mb-4">DEDICATED SERVICES</p>
                    <h1 className="text-3xl font-medium mb-4">Think big and build fast with the enterprise service plan</h1>
                    <p className="text-lg font-normal mb-16">
                    Success is not only choosing the right product, it’s having the know-how to get the most from it. We're your partner for consulting expertise, integration services, 
                    platform uptime, and enterprise-grade support—all in a single package.    
                    </p>
                    <a href="/" className="underline uppercase font-medium tracking-widest text-sm">SEE OUR SUPPORT PLANS</a>
                </div>
                <div>
                    <img src={RightImg2} alt="" />
                </div>
            </div>
        </div>
        </div>
    )
}

export default Whenphoning
