import React from "react";
import UniImg from "./../../images/Contact-center-integrations.png";

function Header() {
  return (
    <div>
      {" "}
      <div className="header-gradient">
        <div className="max-w-7xl m-auto md:py-12 py-8 px-8">
          <div className="grid md:grid-cols-2 grid-cols-1 gap-10">
            <div>
              <p className="text-xs font-normal text-white tracking-widest mb-4">
                CONTACT CENTERS
              </p>
              <h1 className=" font-medium text-white md:text-5xl text-4xl mb-4">
                The cloud contact center software that brings it all together
              </h1>
              <p className="md:text-lg text-base  font-normal text-white">
                When you boost the agent experience, you help them to deliver an
                all-around better customer experience. And that can positively
                influence your sales and service delivery. Took a look at Vonage
                Contact Center (VCC) and how we can positively impact your:
              </p>
              <ul className="list-disc text-white ml-5 my-5">
                <li>Team productivity and satisfaction.</li>
                <li>Controlled costs. </li>
                <li>Customer first-call resolutions.</li>
                <li>Integrated CRM.</li>
                <li>And more.</li>
              </ul>
              <div className="flex flex-wrap md:justify-start justify-center">
                <a
                  href="/"
                  className="bg-white border-white border-2 text-black px-8 mb-6 mr-6 py-6 rounded-lg"
                >
                  Contact Us
                </a>
                <a
                  href="/"
                  className="border-white border-2 text-white px-8 mb-6 mr-6 py-6 rounded-lg"
                >
                  See plans
                </a>
              </div>
            </div>
            <div>
              <img src={UniImg} alt="" />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Header;
