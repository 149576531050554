import React from "react";
import Index from "../views/ContactCenter/Index";
import Layout from "../components/Layout";

export default function ContactCenter({location}) {
  return (
    <div>
 <Layout location={location}>
        <Index  location={location}/>
      </Layout>
    </div>
  );
}
